import React, { useEffect, useRef, startTransition } from 'react';
import {
    AmbientLight,
    DirectionalLight,
    LinearSRGBColorSpace,
    Mesh,
    MeshPhongMaterial,
    PerspectiveCamera,
    Scene,
    SphereGeometry,
    UniformsUtils,
    WebGLRenderer,
} from 'three';
import fragmentShader from './displacement-sphere-fragment.glsl';
import vertexShader from './displacement-sphere-vertex.glsl';

const HeroBackground: React.FC = () => {
    const start = useRef(Date.now());
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const renderer = useRef<WebGLRenderer | null>(null);
    const camera = useRef<PerspectiveCamera | null>(null);
    const scene = useRef<Scene | null>(null);
    const uniforms = useRef<{ [key: string]: any } | null>(null);
    const sphere = useRef<Mesh | null>(null);

    useEffect(() => {
        const { innerWidth, innerHeight } = window;

        // Initialize WebGLRenderer
        renderer.current = new WebGLRenderer({
            canvas: canvasRef.current!,
            antialias: false,
            alpha: true,
            powerPreference: 'high-performance',
            failIfMajorPerformanceCaveat: true,
        });
        renderer.current.setSize(innerWidth, innerHeight);
        renderer.current.setPixelRatio(window.devicePixelRatio);
        renderer.current.outputColorSpace = LinearSRGBColorSpace;

        // Initialize PerspectiveCamera
        camera.current = new PerspectiveCamera(54, innerWidth / innerHeight, 0.1, 100);
        camera.current.position.z = 52;

        // Initialize Scene
        scene.current = new Scene();

        // Create Material with custom shaders
        const material = new MeshPhongMaterial();
        material.onBeforeCompile = (shader) => {
            uniforms.current = UniformsUtils.merge([
                shader.uniforms,
                { time: { value: 0 } },
            ]);

            shader.uniforms = uniforms.current;
            shader.vertexShader = vertexShader;
            shader.fragmentShader = fragmentShader;
        };

        // Add Sphere Geometry
        startTransition(() => {
            const geometry = new SphereGeometry(32, 128, 128);
            sphere.current = new Mesh(geometry, material);
            sphere.current.position.z = 0;
            scene.current!.add(sphere.current);
        });

        // Add Lighting
        const dirLight = new DirectionalLight(0xFFFFFF, 1.8);
        const ambientLight = new AmbientLight(0xFFFFFF, 2.7);
        dirLight.position.set(100, 100, 200);
        scene.current!.add(dirLight, ambientLight);

        // Handle Window Resize
        const handleResize = () => {
            const { innerWidth, innerHeight } = window;
            if (renderer.current) {
                renderer.current.setSize(innerWidth, innerHeight);
            }
            if (camera.current) {
                camera.current.aspect = innerWidth / innerHeight;
                camera.current.updateProjectionMatrix();
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);

            if (renderer.current) {
                renderer.current.dispose();
                renderer.current = null;
            }

            if (scene.current) {
                scene.current.traverse((object) => {
                    if (object instanceof Mesh) {
                        object.geometry?.dispose();
                        if (Array.isArray(object.material)) {
                            object.material.forEach((mat) => mat.dispose());
                        } else {
                            object.material?.dispose();
                        }
                    }
                });
                scene.current = null;
            }
        };
    }, []);

    useEffect(() => {
        // Animation loop
        const animate = () => {
            requestAnimationFrame(animate);

            if (uniforms.current) {
                uniforms.current.time.value = 0.00005 * (Date.now() - start.current);
            }

            if (sphere.current) {
                sphere.current.rotation.z += 0.001;
            }

            if (renderer.current && scene.current && camera.current) {
                renderer.current.render(scene.current, camera.current);
            }
        };

        animate();
    }, []);

    return (
        <div className="absolute inset-0">
            <canvas ref={canvasRef} className="absolute inset-0 w-full h-full" />
            <div className="absolute inset-0 w-full h-full bg-gradient-to-t from-bg-dark to-transparent" />
        </div>
    );
};

export default HeroBackground;
