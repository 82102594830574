import {
    SiCplusplus,
    SiNeovim,
    SiRust,
    SiKubernetes,

    SiZig,

} from "react-icons/si";
import {IconType} from "react-icons";
import { FaPython, FaJava, FaNode } from 'react-icons/fa';
export type ProjectProps = {
  id: number;
  name: string;
  description: string;
  technologies: IconType[];
  techNames: string[];
  techLinks: string[];
  github: string;
  demo: string;
  image: string;
  available: boolean;
};

export const projects = [
    {
        id: 0,
        name: "TechTrail",
        description:
            "Teaching coding skills to middle schoolers.",
        technologies: [FaPython],
        techNames: ["Phyton"],
        techLinks: ["https://www.python.org/"],
        github: "https://github.com/nuIIpointerexception/",
        demo: "https://github.com/nuIIpointerexception/",
        image: "/projects/techtrails1.webp",
        available: false,
    },
    {
        id: 1,
        name: "Minecraft - Space Event",
        description:
            "This Minecraft Event with 1,000 players, custom models and textures",
        technologies: [FaJava, SiKubernetes, FaNode],
        techNames: ["Java", "Kubernetes", "NodeJS"],
        techLinks: ["https://cplusplus.com/", "https://www.rust-lang.org/", "https://neovim.io/"],
        github: "https://github.com/marcusk-studio",
        demo: "https://marcusk.fun/",
        image: "/projects/space.webp",
        available: true,
    },
    {
        id: 2,
        name: "Coming Soon",
        description:
            "I'm currently working on a couple of projects. I'll update this section as soon as I'm done.",
        technologies: [FaNode],
        techNames: ["NodeJS"],
        techLinks: ["https://github.com/nuIIpointerexception/"],
        github: "https://github.com/nuIIpointerexception/",
        demo: "https://github.com/nuIIpointerexception/",
        image: "/projects/construction.webp",
        available: false,
    },
];
