import React from 'react';
import Image from 'next/image';

interface LogoProps {
    width?: number;
    height?: number;
    className?: string; // Add className as an optional prop
}

const Logo: React.FC<LogoProps> = ({ width = 100, height = 100, className }) => {
    return (
        <Image
            className={className} // Use the className prop
            src="/logo.svg"
            alt="Logo"
            width={width}
            height={height}
        />
    );
};

export default Logo;
