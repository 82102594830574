import { spaceGrotesk } from "../fonts/spaceGrotesk.ts";
import { motion } from "framer-motion";
import Logo from "../components/svg/Logo.tsx";
import HeroBackground from "../components/background/HeroBackground.tsx";
import React from "react";

const Hero = () => {
    return (
        <motion.section
            className="relative z-10 flex h-[100vh] w-screen justify-center"
            id="home"
            initial="initial"
            animate="animate"
        >
            
            <HeroBackground/>


            <div className="mt-10 flex flex-col items-center justify-center sm:mt-0">
                <div
                    className={`relative flex flex-col items-center justify-center ${spaceGrotesk.className} pointer-events-none`}
                >
                    <Logo width={300} height={300} />
                </div>
            </div>
        </motion.section>
    );
};

export default Hero;
