import React, { useEffect } from 'react';
import gsap from 'gsap';
import Logo from '../svg/Logo'; // Adjusted relative path

const PreLoader: React.FC = () => {
    useEffect(() => {
        const tl = gsap.timeline();

        // Preloader Animation
        const preLoaderAnim = () => {
            tl.to('.logo-container', {
                duration: 0,
                opacity: 1,
                ease: 'Power3.easeOut',
            })
                .from('.logo-container svg', {
                    duration: 1,
                    delay: 0.5,
                    scale: 0.5,
                    opacity: 0,
                    ease: 'Power3.easeOut',
                })
                .to('.logo-container svg', {
                    duration: 0.5,
                    scale: 1,
                    opacity: 0,
                    ease: 'Power3.easeOut',
                })
                .to('body', {
                    duration: 0.01,
                    css: { overflowY: 'scroll' },
                    ease: 'power3.inOut',
                })
                .to(
                    '.preloader',
                    {
                        duration: 1,
                        height: '0vh',
                        ease: 'Power3.easeOut',
                        onComplete: mobileLanding,
                    },
                    '-=0.5'
                )
                .to('.preloader', {
                    duration: 0,
                    css: { display: 'none' },
                });
        };

        const mobileLanding = () => {
            if (window.innerWidth < 763) {
                tl.from('.landing__main2', {
                    duration: 0.75,
                    delay: 0,
                    opacity: 0,
                    y: 80,
                    ease: 'expo.easeOut',
                });
            }
        };

        preLoaderAnim();
    }, []);

    return (
        <div
            className="preloader gap-[5px] overflow-hidden"
            style={{
                height: '100vh',
                width: '100%',
                background: '#000000',
                color: '#e5ebf2',
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 55,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
            }}
        >
            <div
                className="logo-container flex h-60 items-center justify-center overflow-hidden"
                style={{
                    height: '60px',
                }}
            >
                <Logo className="w-16 h-16 text-[#e4ded7]" />
            </div>
        </div>
    );
};

export default PreLoader;
